<template>
  <div>
    <b-button v-b-modal.createCheckinModal v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="gradient-success" block>
      เพิ่ม
    </b-button>

    <b-modal v-model="modal" size="lg" id="createCheckinModal" title="เพิ่มวัน">
      <div class="row" v-for="(reward, index) in form.rewards">
        <div class="col-4">
          <b-form-group :label="`รางวัล ${index + 1}`" label-for="type">
            <b-form-select v-model="reward.type" :options="rewardOptions"></b-form-select>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group label="มูลค่า" label-for="value">
            <b-form-input v-model="reward.value"></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-end">
        <b-button @click="addReward" size="sm" variant="info">เพิ่มรางวัล</b-button>
      </div>

      <template #modal-footer>
        <b-button variant="success" @click="submit()">
          เพิ่ม
        </b-button>
        <b-button variant="danger" @click="cancel()">
          ยกเลิก
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BTab, BFormRadio, BTabs, BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTab, BFormRadio, BTabs, BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
  },
  directives: {
    Ripple
  },
  props: ['gameOptions'],
  emits: ['submit'],
  data: () => ({
    modal: false,
    rewardOptions: [
      { text: 'เพชร/ตั๋ว/ชิป', value: 'CHIP' },
      { text: 'พ้อยท์/จิ๊กซอว์/ชิ้นส่วนเพชร', value: 'POINT' },
      { text: 'เครดิต', value: 'CREDIT' },
      { text: 'รูปโปรไฟล์', value: 'AVATAR' },
    ],
    form: {
      rewards: [
        { type: 'CHIP', value: 1 }
      ]
    }
  }),
  methods: {
    submit() {
      this.$emit('submit', this.form)
      this.modal = false
      this.clearForm();
    },
    addReward() {
      this.form.rewards.push({ type: 'CHIP', value: 1 })
    },
    cancel() {
      this.modal = false;
      this.clearForm();
    },
    clearForm() {
      this.form = {
      rewards: [
        { type: 'CHIP', value: 1 }
      ]
    }
    }
  }
}
</script>